.welcomePicture
{
  width: 100%;
  z-index: 0;
}

h2 {
  text-align: center;
  color: #333132;
  font-family: Ubuntu, Candara, Futura, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
}

h2:hover {
  color:#53c2e8;
}