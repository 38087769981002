.matchblatt {
  padding: .5em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 6px;
  margin-left: 6px;
}
.timeline-headertable {
  table-layout: fixed;

}
.timeline-list {
  width: 100%;
  margin-bottom: 2em;
  table-layout: fixed;
}

.timeline-header {
  text-align: center;
}

.timeline-betragspalte {
  text-align: right;
  width: 3.2em;
}

.timeline-gevierteilt > *{
  width: 25%;
  float: left;
  text-align: center;
}

.timeline-approval {
  background-color: rgba(0, 128, 0, 0.144);
  text-align: center;
}

.timeline-draft {
  color: rgb(0, 0, 0);
  text-align: center;
}

.timeline-team-header  {
  font-weight: bold;
  border: 2px solid black;
  margin: 1em;
  padding: 1em;
}
.timeline-team  {
  padding-right: .5em;
  padding-left: .5em;
  text-overflow: ellipsis;
  overflow: hidden; 
}

.timeline-vs {
    width: 3em;
    text-align: center;
    background-color: rgba(129, 0, 0, 0.507);
    border-radius: 8px;
}

.timeline-total-line {
  font-weight: bold;
  border: 2px solid black;
}

.timeline-wiis {
  background-color: rgb(255, 255, 255);
}
.timeline-wiis-team1 {
  text-align: left;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; 
}
.timeline-wiis-team2 {
  text-align: right;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; 
}
.timeline-hand-line > td > div {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.timeline-hand-line > td {
  font-weight: bold;
}
.timeline-label {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden; 
}
.timeline-time-indication {
  float:right;
}

.timeline-gschobne{
  font-style: italic;
}
.timeline-left {
  text-align: left !important;
}
.timeline-list > * > tr > td {
  @extend %timeline-cell;
}

.timeline-gap-line > td {
  border-left: none !important;
  border-right: none !important;
  height: 5rem;
}
.timeline-gap-line > td{
  vertical-align: bottom;
  font-weight: bold;
}
%timeline-cell {
  border-color: black;
  border-width: 1px;
  border-style: dotted;
  text-align: center;
}