.j-group {
  border: 2px solid;
}
.j-group:hover {
  background-color: rgb(197, 197, 197);
}
.j-table{
  width: 100%;
}
.j-table > thead > tr {
  border: 2px solid;
  font-weight: bold;
}
.j-mainrow {
  background-color: rgba(255, 192, 203, 0.5);
  font-weight: bold;
  border-bottom: 2px solid black;
}
.j-subrow {
  margin-left: 2em;
  font-size: 0.75em;
  font-weight: lighter;
  border: 1px dotted;
}
.j-subrow:hover {
  background-color: rgb(158, 158, 158);
}
.j-additional {
  display:none;
}
.j-mobile {
  display:unset;
}
@media only screen and (min-width: 768px) {
  .j-additional {
    display:unset;
  }
  .j-mobile {
    display:none;
  }
}
