.dra {
  background-color: aqua;
}

.standing {
  padding: 0;
}

.standing > div {
  float: left;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color:  #ffffff;
  text-align: center;
  margin-bottom: 2px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}

.standing-goal {
  border-color:  #333333 !important;
  border-radius: 8px;
}

.nav-element {
  float: left;
  background-color: rgb(56, 56, 56);
  border-color: #ffc0cb;
  border-style: solid;
  text-align: center;
  color: #ffc0cb;
  text-overflow: ellipsis;
  overflow: hidden; 
}
.nav-element > *{
  font-weight: bold;
}
.nav-big > *{
  height: 3em;
  font-size: 1.5em;
  line-height: 3em;
  border-width: 2px;
}
.nav-small > *{
  height: 2.5em;
  font-size: 1em;
  line-height: 2.5em;
  border-width: 1px;
}
.nav-element-current {
  background-color: rgb(104, 106, 199);
  color: rgb(0, 0, 0);
  border-color: #ffc0cb;
}
.nav-element-disabled {
  background-color: rgb(207, 207, 207);
  color: rgb(48, 48, 48);
  border-color: #ffc0cb;
}
.nav-element-empathize {
  background-color: rgb(34, 106, 173);
  color: rgb(255, 255, 255);
  font-weight: bold;
  border-color: #ffc0cb;
	-webkit-animation: bounce-top 3s infinite alternate-reverse both;
  animation: bounce-top 3s infinite alternate-reverse both;
}
.nav-element-cancel {
  background-color: rgb(255, 88, 88);
  color: rgb(0, 0, 0);
  border-color: #ff0000;
}
.nav-element-swipethize {
  background-color: rgb(0, 77, 77);
  color: rgb(192, 190, 146);
  font-weight: bold;
}
.wiise {
  width: 25%;
  background-color: rgb(77, 133, 197) !important;
}
.wiis {
  background-color: rgba(77, 133, 197, 0.5);
  border: 1px solid black;
  border-radius: 3px;
  float:left;
  margin-right: 1em;
  margin-top: 8px;
  padding: 0.1em;
}
.wiis > div {
  float:left;
  margin-left: .2em;
  margin-right: .2em;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  max-width: 10em;
}
.schieber-label {
  font-weight: bold;
}
.schiebe {
  background-color: #ffa500 !important;
  width: 25%;
}
.asaege {
  background-color: #31af31;
  font-weight: bold;
  font-size: 2em;
  float: left;
  height:2.7em;
  line-height:2.5em;
  text-align: center;
  vertical-align: middle;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
}
.asaegefarbe {
  width: 25%;
}
.matchasaege{
  float: left;
  width: 8.33%;
}
.matchasaege > div {
  height:2.5em;
  text-align: center;
  line-height: 2.5em;
  border-radius: 8px;
  background-color: #fff1d6;
}
.matchAktiv {
  border: 2px solid #000000
}
.matchInAktiv {
  border: solid 2px #ffffff;
}
.asaegeoption {
  background-color: #31af31;
}
.asaegeselected {
  background-color: #31af31;
  border: solid 3px #000000;
}
.asaegeunselected {
  background-color: rgba(128, 128, 128, 0.25);
}
.gschobe {
  background-color: #ffa500;
  border: solid 3px #000000;
  width: 25%;
}
.finissiere {
  background-color: #364bac;
  border: solid 3px #000000;
  color: #bebebe;
  width: 50%;
  font-weight: bold;
  font-size: 2em;
  float: left;
  line-height:2em;
  text-align: center;
  vertical-align: middle;
  border-style: solid;
  border-width: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notfinissiert {
  background-color: #b9b9b9;
  border: solid 3px #7e7e7e;
  color: #7e7e7e;
  width: 50%;
}

.asaeger { 
  width: 66.66%;
  float: left;
  height:5em;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
}
.asaeger > * {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}
.asaegerplayer {
  line-height: 3em;
  font-size: 1.5em;
  height: 100%;
  text-align: center;
  font-weight: bold;
}
.hetgschobeplayer{
  height: 50%;
  line-height:2.5em;
  color: rgba(128, 128, 128, 0.75);
  text-decoration: line-through;
  text-align: center;
}
.gschobneplayer{
  line-height:2em;
  font-weight: bold;
  text-align: center;
  border: solid 3px #00000000;
  background-color: #ffa600a6;
  padding-bottom: -16px;
}
.partnerplayer{
  display: none;
}
.puenkt {
  width: 25%;
  background-color: rgb(255, 241, 214);
  float:left;
  height:5em;
  line-height:5em;
  border-color: #000000;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
}
.puenkt > input{
  font-size: 2em;
  width: 100%;
  padding: 2px;
  margin: 2px;
  border:none;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
}
.teppich {
  background-image: url("jassteppich.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width:100vw;
  max-width:555px;
  height:100vw;
  max-height:555px;
  position: relative;
}
.teppichcontainer {
  float: left;
  text-align: center;
}
@media (min-width: 576px) {
  .teppichcontainer {
    margin-left: 0px;
  }
}
@media (min-width: 768px) {
  .teppichcontainer {
    margin-left: 82px;
  }
}
@media (min-width: 992px) {
  .teppichcontainer {
    margin-left: 202px;
  }
}
@media (min-width: 1200px) {
  .teppichcontainer {
    margin-left: 292px;
  }
}

.teppich > div {
  position: absolute;
  text-align: center;
  font-weight: 100;
}
.teppich > span {
  position: absolute;
  text-align: center;
}
.teppich > span > * {
  font-size: 60px;
}
.teppichp3 {
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
}
.teppichp2 {
  right: 5%;
  top: 50%;
  transform: translate(0, -50%);
}
.teppichp1 {
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 0);
}
.teppichp4 {
  left: 5%;
  top: 50%;
  transform: translate(0, -50%);
}
.p1top2 {
  bottom: 32.5%;
  right: 32.5%;
  transform: translate(+50%, +50%) rotate(135deg);
}
.p2top3 {
  top: 32.5%;
  right: 32.5%;
  transform: translate(+50%, -50%) rotate(45deg);
}
.p4top1 {
  bottom: 32.5%;
  left: 32.5%;
  transform: translate(-50%, +50%) rotate(-135deg);
}
.p3top4 {
  top: 32.5%;
  left: 32.5%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.teppichSelect1 {
  top: 32.5%;
  left: 50%;
  transform: translate(-50%, 0);
}
.teppichSelect2 {
  bottom: 32.5%;
  left: 50%;
  transform: translate(-50%, 0);
}
.player-dropdown {
  width: 130px;
  text-overflow: ellipsis;
}
.points-dropdown {
  width: "5em";
  font-weight: bold;
}
.current-player {
  border: 0.4em rgba(0, 0, 0, 0.137) solid;
  border-radius: 0.4em;
  animation: blink 3s;
  animation-iteration-count: infinite;
}

.kachel {
  background-color: rgba(77, 133, 197, 0.75);
  border: 1px solid black;
  border-radius: .2em;
  margin-bottom: .2em;
  text-align: center;
  vertical-align: middle;
  font-size: 1.2em;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-preview {
  max-width: 80px;
  max-height: 80px;
  margin-right: 20px;
  margin-left: 20px;
}
.upload-preview-container {
  width: 100%;
  text-align: center;
}
@keyframes blink { 50% { border-color:rgb(32, 19, 19) ; }  }

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
