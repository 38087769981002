.global-header-warn {
  background-color: rgb(211, 211, 129);
  text-align: center;
  border: 1px solid rgb(80, 80, 7);
  border-radius: 3px;
}

.jasstafel {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width:100vw;
  max-width:440px;
  position: relative;
  align-content: center;
  color: white;
  margin-bottom: 0;
}
.jasstafel_z {
  background-image: url("Jasstafel_z.jpg");
  height:132.045vw;
  max-height:581px;
  font-family: sans-serif;
  font-size: 1.8em;
}
.jasstafel_blank {
  background-image: url("Jasstafel_leer.jpg");
  height:132.841vw;
  max-height:581px;
  font-family: 'Caveat', cursive;
}
@media (min-width: 576px) {
  .jasstafel {
      margin-left: 0px;
  }
}
@media (min-width: 768px) {
  .jasstafel {
      margin-left: 144px;
  }
}
@media (min-width: 992px) {
  .jasstafel {
      margin-left: 264px;
  }
}
@media (min-width: 1200px) {
  .jasstafel {
      margin-left: 354px;
  }
}
.tafelcontent {
  padding:17%;
}
.image-s {
  max-width: 64px;
  max-height: 64px;
}
.image-m {
  max-width: 192px;
  max-height: 192px;
}
.image-l {
  max-width: 256x;
  max-height: 256x;
}
.image-xl {
  width: 1024px;
}
.image-max {
  width: 100%;
}